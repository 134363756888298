export default {
  lineNumberColor: `#4d4d4c`,
  lineNumberBgColor: `white`,
  backgroundColor: `white`,
  textColor: `#4d4d4c`,
  substringColor: `#4d4d4c`,
  keywordColor: `#8959a8`,
  attributeColor: `#eab700`,
  selectorAttributeColor: `#8959a8`,
  docTagColor: `#4d4d4c`,
  nameColor: `#c82829`,
  builtInColor: `#f5871f`,
  literalColor: `#f5871f`,
  bulletColor: `#718c00`,
  codeColor: `#4d4d4c`,
  additionColor: `#718c00`,
  regexpColor: `#c82829`,
  symbolColor: `#718c00`,
  variableColor: `#c82829`,
  templateVariableColor: `#c82829`,
  linkColor: `#f5871f`,
  selectorClassColor: `#c82829`,
  typeColor: `#f5871f`,
  stringColor: `#718c00`,
  selectorIdColor: `#c82829`,
  quoteColor: `#8e908c`,
  templateTagColor: `#4d4d4c`,
  deletionColor: `#c82829`,
  titleColor: `#4271ae`,
  sectionColor: `#4271ae`,
  commentColor: `#8e908c`,
  metaKeywordColor: `#4d4d4c`,
  metaColor: `#f5871f`,
  functionColor: `#4d4d4c`,
  numberColor: `#f5871f`,
}
