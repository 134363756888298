export default {
  lineNumberColor: `#383a42`,
  lineNumberBgColor: `#fafafa`,
  backgroundColor: `#fafafa`,
  textColor: `#383a42`,
  substringColor: `#e45649`,
  keywordColor: `#a626a4`,
  attributeColor: `#50a14f`,
  selectorAttributeColor: `#e45649`,
  docTagColor: `#a626a4`,
  nameColor: `#e45649`,
  builtInColor: `#c18401`,
  literalColor: `#0184bb`,
  bulletColor: `#4078f2`,
  codeColor: `#383a42`,
  additionColor: `#50a14f`,
  regexpColor: `#50a14f`,
  symbolColor: `#4078f2`,
  variableColor: `#986801`,
  templateVariableColor: `#986801`,
  linkColor: `#4078f2`,
  selectorClassColor: `#986801`,
  typeColor: `#986801`,
  stringColor: `#50a14f`,
  selectorIdColor: `#4078f2`,
  quoteColor: `#a0a1a7`,
  templateTagColor: `#383a42`,
  deletionColor: `#e45649`,
  titleColor: `#4078f2`,
  sectionColor: `#e45649`,
  commentColor: `#a0a1a7`,
  metaKeywordColor: `#383a42`,
  metaColor: `#4078f2`,
  functionColor: `#383a42`,
  numberColor: `#986801`,
}
